body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: black;
  overflow: hidden;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body.dark-mode {
  background-color: #1a1a1a;
  color: white;
}

.app {
  display: flex;
}

.sidebar {
  background-color: #fff;
  padding: 20px;
  position: fixed;
  height: 100%;
  width: 9%;
  overflow: auto;
}

.sidebar.dark-mode {
  background-color: #2a2a2a;
}

.sidebar h1 {
  cursor: pointer;
  text-align: center;
}

.sidebar h2 {
  text-align: center;
}

.sidebar p {
  text-align: center;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  cursor: pointer;
  margin: 10px 0;
}

.content {
  margin-left: 12%;
  margin-right: 4%;
  padding: 20px;
  width: calc(100% - 220px);
}

.carousel-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
}

.carousel-image {
  max-width: 100%;
  max-height: 80vh;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.45);
}

.carousel-image.dark-mode {
  box-shadow: 4px 4px 6px rgba(226, 225, 225, 0.2);
}

.carousel-button {
  position: absolute;
  top: 50%;
  background: rgba(255, 255, 255, 0);
  border: none;
  font-size: 2rem;
  cursor: pointer;
  margin-left: 2%;
  margin-right: 2%;
  color: black; /* Default color for light mode */
}

.carousel-button.left {
  left: 20px;
}

.carousel-button.right {
  right: 20px;
}

.carousel-button.dark-mode {
  color: white; /* Color for dark mode */
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.94);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.full-screen-overlay.dark-mode {
  background: rgba(0, 0, 0, 0.94);
}

.full-screen-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 5%;
  right: 3%;
  background: none;
  border: none;
  color: black;
  font-size: 42px;
  cursor: pointer;
}

.close-button.dark-mode {
  color: white;
}

.preview-bar {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
}

.preview-bar.dark-mode {
  background-color: #2a2a2a;
}

.image-info-box {
  position: relative;
  top: 50%;
  transform: translate(0, -85%);
  background-color: #fff;
  padding: 4%;
  padding-bottom: 1%;
  border: 1px solid #ccc;
  margin-top: 5%;
}

.image-info-box.dark-mode {
  background-color: #2a2a2a;
  border-color: #fdfdfd98;
}

.film-info, .location-info {
  margin-bottom: 1%;
}

.film-image {
  width: 100%;
  height: auto;
  margin-top: 2.5%;
}

.map-preview {
  width: 100%;
  height: auto;
  margin-top: 5px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.preview-thumbnail {
  width: 80px;
  height: 60px;
  margin: 0 5px;
  cursor: pointer;
  object-fit: cover;
}

.thumbnail-gallery {
  display: flex;
  flex-wrap: wrap;
}

.thumbnail-container {
  width: calc(33.333% - 10px);
  margin: 5px;
}

.thumbnail-image {
  width: 100%;
  height: auto;
}

.thumbnail-gallery.active {
  overflow-y: scroll;
}

.thumbnail-gallery.active::-webkit-scrollbar {
  display: none;
}

* {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  -webkit-user-drag: none;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1a1a1a;
    color: white;
  }

  .sidebar {
    background-color: #2a2a2a;
  }

  .full-screen-overlay {
    background: rgba(0, 0, 0, 0.94);
  }

  .close-button {
    color: white;
  }

  .preview-bar {
    background-color: #2a2a2a;
  }

  .image-info-box {
    background-color: #2a2a2a;
    border-color: #fdfdfd98;
  }

  .carousel-button {
    color: white; /* Color for dark mode */
  }

  .carousel-image {
    box-shadow: 4px 4px 6px rgba(226, 225, 225, 0.2); /* Box shadow for dark mode */
  }
}
